import React, { FC } from 'react';
import { graphql, PageProps, Link as GatsbyLink } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Hidden, Typography } from '@material-ui/core';
import { postStyle } from '../templates/postStyle';
import { SEO } from '../components/seo/seo';
import { DefaultHeader } from '../components/Header/Header';
import { Card } from '../components/Card/Card';
import { CardBody } from '../components/Card/CardBody';
import { Button } from '../components/Button/Button';
import { DefaultFooter } from '../components/Footer/Footer';
import { Parallax } from '../components/Parallax/Parallax';
import { KeyboardArrowDownRounded } from '@material-ui/icons';
import { infoColor, primaryColor, successColor, warningColor } from '../styles/materialKitBase';

type SeriesProps = PageProps<{
  series: {
    edges: {
      node: {
        id: string;
        frontmatter: {
          title: string;
          series_main: boolean | null;
          slug: string;
        };
        html: string;
      };
    }[];
  };
}>;

const useStyles = makeStyles(postStyle);

const SeriesPage: FC<SeriesProps> = ({ data }) => {
  const {
    series: { edges: allSeriesNodes },
  } = data;

  const classes = useStyles();

  return (
    <div style={{ background: 'linear-gradient(#212121, #414345)' }}>
      <SEO featuredImage="/img/bloggingStation.jpg" title="Article Series" />
      <DefaultHeader />
      <Parallax
        image="/img/Series.jpg"
        className={classes.parallax}
        filter="dark"
        // style={{ height: '80vh', overflow: 'hidden' }}
      >
        <div className={classes.container}>
          <Grid container justify="center">
            <Hidden xsDown>
              <KeyboardArrowDownRounded
                style={{ color: primaryColor[0], bottom: '70px', position: 'absolute', fontSize: '3.2rem' }}
              />
              <KeyboardArrowDownRounded
                style={{
                  color: warningColor[0],
                  bottom: '83px',
                  position: 'absolute',
                  fontSize: '3.2rem',
                  animation: 'bounce 4s infinite',
                }}
              />
            </Hidden>
            <Grid item md={9} className={classes.textCenter}>
              <Typography component="h1" variant="h3" className={classes.title}>
                Article Series
              </Typography>
              <Typography component="h2" variant="h6" className={classes.title}>
                Grouped by <span style={{ color: successColor[0] }}>topic</span> or{' '}
                <span style={{ color: infoColor[0] }}>technology</span>
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Parallax>

      <div className={classes.main} style={{ marginBottom: '60px' }}>
        <div className={classes.container}>
          <Grid container justify="center">
            <Grid item xs={12} sm={9} md={6}>
              {allSeriesNodes.map(({ node }) => {
                return (
                  <Card id={`series-${node.id}`} key={node.id} className={classes.card4}>
                    <CardBody plain background={false}>
                      <Typography variant="h5" component="h3" className={classes.cardTitle}>
                        <GatsbyLink to={node.frontmatter.slug}>{node.frontmatter.title}</GatsbyLink>
                      </Typography>
                      <Typography variant="subtitle1" component="h5" className={classes.description}>
                        <div dangerouslySetInnerHTML={{ __html: node.html }} />
                      </Typography>
                      <br />
                      <Button round color="primary" component={GatsbyLink} to={node.frontmatter.slug}>
                        Dive into this series
                      </Button>
                      <br />
                      <br />
                    </CardBody>
                  </Card>
                );
              })}
            </Grid>
          </Grid>
        </div>
      </div>
      <DefaultFooter />
    </div>
  );
};
export default SeriesPage;

export const query = graphql`
  query {
    series: allMarkdownRemark(filter: { frontmatter: { series_main: { ne: null } } }) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            series
            series_main
          }
          html
        }
      }
    }
  }
`;
